@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.login {
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;
  position: relative;

  &__comming {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0 0 0 0;
    z-index: 1000;
    background-color: rgba(black, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__background {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;

    &__mask {
      position: absolute;
      z-index: 2;
      background-color: var(--color-elevate);
      height: 100%;
      width: 100%;
      inset: 0 0 0 0;
    }

    &__image {
      z-index: 1;
      object-fit: cover;
      object-position: center center;
      height: 100%;
      width: 100%;
    }
  }

  &__contain {
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0 0 0 0;
    padding: 50px;
    z-index: 2;

    &__form {
      width: 600px;
      height: min-content;
    }

    &__feature {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-radius: var(--border-radius);
      background-color: var(--color-primary);
      width: 100%;
      height: 100%;
    }
  }
}
