@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

%profil-details-typo-title {
  font-size: map-get($writeMode, title-large, font-size);
  font-weight: map-get($writeMode, title-large, font-weight);
  font-style: map-get($writeMode, title-large, font-style);
  color: map-get($writeMode, title-large, color);
}

%profil-details-typo-description {
  font-size: map-get($writeMode, namise-description, font-size);
  font-weight: map-get($writeMode, namise-description, font-weight);
  font-style: map-get($writeMode, namise-description, font-style);
  color: map-get($writeMode, namise-description, color);
}

.profil-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  &__top {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;

    &__identity {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &__icon {
      }

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 20px;
        width: 100%;

        &__write {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2px;

          &__unknown {
            @extend %profil-details-typo-title;
          }

          &__separa {
            margin: 0 10px;

            &__text {
              @extend %profil-details-typo-description;
            }
          }
        }

        &__badge {
          margin-top: 3px;
          display: flex;
          justify-content: center;
          align-items: center;

          &__item {
            margin-right: var(--space);

            &:last-of-type {
              margin-right: 0px;
            }
          }
        }
      }
    }

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;

      &__button {
        display: flex;
        justify-content: center;
        margin-right: 5px;

        &__cancel {
          margin-left: 5px;
        }
      }
    }
  }

  &__bottom {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 30px;
    width: 100%;

    &__group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      &__item {
        width: 100%;

        &__edit,
        &__read {
          margin-bottom: var(--space);
        }

        &__read {
          &__text {
            height: map-get($entry, height, small);
            padding: map-get($entry, padding, small) !important;
          }
        }
      }
    }
  }
}

$maxWithCustomEditable: size(250px);

.profil-details-custom-editable {
  // &:nth-child(2) {
  //   margin-left: 10px;
  // }

  &--textOverflowMask {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: $maxWithCustomEditable;
  }

  &--type {
    &--title {
      white-space: nowrap;
      .profil-details-custom-editable__input,
      .profil-details-custom-editable__text {
        @extend %profil-details-typo-title;
      }

      .profil-details-custom-editable__input__edit {
        min-width: size(150px);
        max-width: $maxWithCustomEditable;
      }
    }

    &--description {
      .profil-details-custom-editable__input,
      .profil-details-custom-editable__text {
        @extend %profil-details-typo-description;
      }

      .profil-details-custom-editable__input__edit {
        min-width: 100px;
        max-width: size(130px);
      }
    }
  }

  &__input {
    position: relative;
    overflow: hidden;
    min-width: 15px;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    padding: 1px 5px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &:hover {
      border: 1px solid var(--color-primary);
      box-shadow: 0px 0px 1px var(--color-primary);
    }

    &__placeholder,
    &__edit {
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
    }

    &__placeholder {
      color: var(--color-disabled);
      position: absolute;
      left: 5px;
      pointer-events: none;
    }

    &__edit {
      display: inline-block;
      outline: none;
      color: inherit;
      white-space: nowrap;
    }
  }
}
