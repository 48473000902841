@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.tree {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 95%;
  overflow-x: hidden;
  overflow-y: visible;
  @include scrollbar();
  padding: 5px;

  &__loader {
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}

.tree-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  &--lastDepth {
    .tree-row__contain__item {
      box-shadow: none !important;
      border-bottom: 1px solid var(--color-border) !important;
      margin: 0px;
      border-radius: 0px !important;
    }

    &:last-of-type {
      .tree-row__contain__item {
        border-bottom: none !important;
      }
    }
  }

  &__contain {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    &__button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: $space;
      width: 15px;

      &__icon {
        transition: all 0.3s ease-in-out;

        &--expanded {
          transform: rotate(90deg);
        }
      }
    }

    &__item {
      width: 100%;
      border-radius: 5px;
      padding: 10px;
      background-color: var(--color-card);
      transition: background-color 0.1s ease-in-out;

      box-shadow: 0px 0px 5px var(--color-shadow);
      margin: 6px 0;
      transition: all 0.1s ease-in-out;

      &:hover {
        background-color: var(--color-primary-alpha-10);
      }

      &__selected {
        background-color: var(--color-selected) !important;
      }
    }
  }

  &__child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}

.tree-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 5px;
  position: sticky;
  top: 0;
}

// .tree-row__contain:has(.tree-row__contain) {
//   .tree-row__contain__item {
//     box-shadow: 0px 0px 5px var(--color-shadow);
//     border-bottom: none !important;
//   }
// }
