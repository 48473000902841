@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.motoristConfirm {
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100dvh;

  &__load,
  &__error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__text {
      margin-top: 25px;
    }
  }

  &__redirect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__contain {
      padding: 30px;
      border-radius: var(--border-radius);
      background-color: var(--color-layout);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      * {
        pointer-events: none;
        transition: all 0.3s ease-in-out !important;
      }

      &__text {
        margin-right: 15px;
      }

      &:hover {
        * {
          color: var(--color-primary);
        }
      }
    }
  }

  &__contain {
    padding: 30px;
  }
}
