@use 'sass:color';
@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.popup-info-node {
  width: 100%;
  height: 100%;
  max-height: 400px;
}

.popup-info-description {
  flex-wrap: nowrap;

  .descriptiontext__item:not(.descriptiontext__item:last-of-type) {
    margin-right: 15px;
  }
}
