@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.session-infos {
  display: flex;
  justify-content: center;
  align-items: center;

  &__pointer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;

    * {
      pointer-events: none;
    }
  }
}
