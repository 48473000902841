@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.run-wizard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 100%;
  height: size(20px);
  width: size(20px);
}
