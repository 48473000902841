@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.motoristsCreate {
  .formitem {
    display: block !important;
    height: 100% !important;

    & > span {
      display: block !important;
      height: 100% !important;
    }

    .input,
    input,
    .ant-row,
    .ant-col,
    [class^='ant-form-item'] {
      height: 100% !important;
    }
  }
}
