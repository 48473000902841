@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.action {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 4px 0px;

  &:first-of-type {
    padding-top: 0px;
  }

  &:last-of-type {
    padding-bottom: 0px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 18px;
    cursor: pointer;

    &__text--pure {
      transition: font-weight 0.2s ease-in-out;

      &:hover {
        font-weight: bold;
      }
    }

    &__switch {
      display: flex;
      justify-content: flex-end;
      min-width: 200px;
      padding-left: 50px;
    }
  }
}
