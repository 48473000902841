@use 'sass:color';
@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.switcher-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
