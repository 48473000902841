@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.product-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: visible;

  &__contain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    @include scrollbar();
    padding: 20px 5px;

    &__line {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &__title {
        margin-bottom: 20px;
      }

      &__product {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 40px;

        &__item {
          margin-right: 20px;

          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
