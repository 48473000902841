@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

%credential-card-size {
  width: size(280px);
  aspect-ratio: 2 / 1.15;
  cursor: pointer;
}

%credential-card-radius {
  border-radius: 5px;
}

$credential-mini-group-space: 10px;

//! Credential MINI
.credential-mini {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  height: size(22px);
  padding: 0 5px;
  border-radius: 5px;

  &__value {
    margin-left: 5px;
  }
}

//! Credential MINI GROUP
.credential-mini-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .credential-mini {
    margin-right: $credential-mini-group-space;

    &:last-of-type {
      margin-right: 0px;
    }
  }

  &__visible {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__hidden {
    margin-left: $credential-mini-group-space;
    cursor: pointer;
    font-size: 14px;
    color: var(--color-primary);
  }
}

//! Credential CARD
.credential-card__action__empty,
.credential-card__contain__info__item__label,
.credential-card__contain__info__item__value {
  font-size: size(14px);
}

.credential-card__contain__head__category {
  font-size: size(17px);
}

.credential-card {
  @extend %credential-card-size;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  * {
    line-height: initial !important;
  }

  &:hover {
    .credential-card__action {
      opacity: 1;
    }
  }

  &__action,
  &__contain {
    padding: 14px;
    @extend %credential-card-radius;
  }

  &__action {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    backdrop-filter: blur(3px);
    background-color: var(--color-elevate);
    transition: all 0.4s ease-in-out;
    flex-wrap: wrap;
    align-content: center;

    &__button {
      margin: 3px;
    }

    &__empty {
      color: var(--color-white) !important;
      font-weight: 500;
    }
  }

  &__contain {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--disabled {
      opacity: 0.2;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &__category {
        font-weight: 700;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 3px;
        position: relative;
        overflow: hidden;
        width: 100%;

        &:last-of-type {
          margin-bottom: 0px;
        }

        &__value,
        &__label {
          white-space: nowrap;
        }

        &__value {
          margin-left: 5px;
          font-weight: 600 !important;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }
}

//! Credential CARD GROUP
.credential-card-group__add,
.credential-card-group__add__text,
.credential-card-group__add__icon,
.credential-card-group__add__icon svg {
  transition: all 0.3s ease-in-out;
}

.credential-card-group {
  display: flex;
  flex-wrap: wrap;
  @include scrollbar();
  width: 100%;
  gap: 20px;
  padding: 10px;

  .credential-card,
  &__add {
    @extend %credential-card-size;
    @extend %credential-card-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background);

    &:hover {
      background-color: var(--color-primary-alpha-10);

      .credential-card-group__add__text,
      .credential-card-group__add__icon,
      .credential-card-group__add__icon svg {
        color: var(--color-primary) !important;
        fill: var(--color-primary) !important;
      }
    }

    &__text {
      margin-left: 5px;
      font-size: size(16px);
      font-weight: 500;
      color: var(--color-label);
    }
  }
}

//! Credential EDIT
.credential-edit {
  width: 500px;
  aspect-ratio: 2 / 1.2;
  @extend %credential-card-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 15px;
  position: relative;

  &__loading {
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: var(--color-elevate);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__category {
      font-size: size(20px);
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      &__button {
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }

    &__entry {
      width: 100%;

      &__item {
        margin-bottom: 5px;

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
  }
}
