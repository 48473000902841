@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.searchbar {
  width: 700px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__contain {
    width: 100%;
    height: 75%;
    max-height: 35px;
    padding: 0px 10px;
    border-radius: 11px;
    background-color: var(--color-layout);
    box-shadow: inset 0px 0px 4px var(--color-shadow);
    display: flex;
    justify-content: center;
    align-items: center;

    &__input {
      width: 100%;
      height: 100%;
      margin: 0 10px;
      outline: none;
      border: none;
      font-size: map-get($writeMode, input, font-size);
      font-weight: map-get($writeMode, input, font-weight);
      font-style: map-get($writeMode, input, font-style);
      color: map-get($writeMode, input, color);
      background-color: transparent;
    }

    &__limit {
      margin-right: 10px;
    }

    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      * {
        pointer-events: none;
      }
    }
  }

  &__result {
    position: absolute;
    bottom: 0px;
    transform: translateY(100%);
    width: 100%;
    background-color: var(--color-layout);
    z-index: 10;
    border-radius: 10px;
    box-shadow: 0px 0px 4px var(--color-shadow);

    &__summary {
      padding: 0px 0 0 0;
      box-shadow: 0px 0px 4px var(--color-shadow);
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 10px 10px 10px;
        cursor: pointer;
        border-bottom: 3px solid var(--color-layout);
        transition: border 0.2s ease-in-out;

        &--select {
          border-bottom: 3px solid var(--color-primary);
        }

        * {
          pointer-events: none;
        }

        &__count {
          margin-left: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: size(17px);
          width: size(17px);
          border-radius: 100%;
          background-color: var(--color-background);

          span {
            font-weight: bold;
            font-size: size(11px);
            line-height: initial;
            color: map-get($writeMode, link, color);
          }
        }
      }
    }

    &__list {
      max-height: 35dvh;
      overflow: auto;
      @include scrollbar();
    }
  }
}
