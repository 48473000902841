@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.product__header__text__title {
  font-size: size(15px);
  font-weight: 500;
}

.product__header__text__description {
  font-size: size(12px);
  font-weight: 300;
  color: var(--color-label);
}

.product__price__value__number,
.product__price--incomplete span {
  font-size: size(21px);
  font-weight: 400;
  color: var(--color-text);
}

.product__price__value__period,
.product__price__complement,
.product__advantage__item__text {
  font-size: size(12px);
  font-weight: 300;
  color: var(--color-text);
}

.product__price__complement {
  text-transform: lowercase;
}

.product__credential__title {
  font-size: size(12px);
  font-weight: 400;
  color: var(--color-text);
}

.product,
.product__advantage {
  transition: all 0.3s ease-in-out;
}

.product {
  display: flex;
  flex-direction: column;
  width: size(290px);
  height: size(350px);
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--color-border);
  overflow: hidden;
  @extend %card;

  &--over:hover,
  &--select {
    background-color: var(--color-primary-alpha-10);
    border: 1px solid var(--color-primary-alpha-100);
    .product__advantage {
      border-top: 1px solid var(--color-primary-alpha-10);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: size(90px);

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;

      &__title {
        width: 100%;
        margin-bottom: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--color-yang);
      }

      &__description {
        height: 100%;
        display: -webkit-box;
        width: 100%;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    &__info {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;

      &__item {
        width: 280px;
      }
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    padding-bottom: 10px;

    &--incomplete {
      padding-bottom: 10px;
    }

    &--empty {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &__value {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      &__number {
        font-size: size(22px);
        font-weight: 400;
        line-height: 1;
        margin-right: 5px;
        text-transform: uppercase;
      }

      &__period {
        text-transform: lowercase;
      }
    }
  }

  &__advantage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 10px;
    border-top: 1px solid var(--color-border);

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        height: 17px;
        aspect-ratio: 1;
      }
    }
  }

  &__credential {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
      margin-bottom: 10px;
    }

    &__contain {
      display: flex;
      justify-content: center;
      align-items: center;

      &__item {
        margin: 0 5px;
      }
    }
  }
}
