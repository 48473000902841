@use 'sass:color';
@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.line-state {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__text {
    margin-left: 7px;
  }
}
