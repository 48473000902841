@use 'sass:color';
@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

$sessionTimelineColor: (
  label: var(--color-label),
  success: var(--color-success),
  error: var(--color-error),
  info: var(--color-info),
);

.timeline {
  display: grid;
  align-items: center;
  grid-template-columns: min-content min-content 1fr;
  gap: 0px 10px;

  &--color {
    @each $name, $item in $sessionTimelineColor {
      &--#{$name} {
        .timeline__details,
        .timeline__step__subject {
          // background-color: color.scale($item, $lightness: 80%);
        }
      }
    }
  }

  &--size {
    &--medium {
      .timeline__step__subject {
        height: 34px;
      }

      .timeline__step__linetop,
      .timeline__step__linebottom {
        width: 3px;
        height: 8px;
      }

      .timeline__details {
        height: 38px;
        padding: 0px 7px;
      }
    }

    &--large {
      .timeline__step__subject {
        height: 40px;
      }

      .timeline__step__linetop,
      .timeline__step__linebottom {
        width: 3px;
        height: 12px;
      }

      .timeline__details {
        height: 48px;
        padding: 0px 10px;
      }
    }
  }

  &__date {
    width: 70px;

    * {
      white-space: wrap;
      text-align: center;
    }
  }

  &__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__linetop,
    &__linebottom {
      background-color: var(--color-intermediate);
    }

    &__subject {
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;

      &__item {
        &--animation {
          animation: load 2.5s linear infinite;

          @keyframes load {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }

  &__details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
    border-radius: 3px;

    &__code {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-right: 50px;
    }

    &__more {
      display: flex;
      justify-content: center;
      align-items: center;

      &__left {
        margin-right: 20px;
      }
    }
  }
}

.timeline-group {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .timeline:first-of-type .timeline__step__linetop,
  .timeline:last-of-type .timeline__step__linebottom {
    visibility: hidden;
  }
}
